<template>
  <div class="body">
    <div class="top" @click="top()">
      <span class="iconfont icon-up"></span>
      <p>TOP</p>
    </div>
    <div class="head">
      <Head></Head>
    </div>
    <div class="market-title">
      <img
        class="bg1"
        src="./img/tuiguang-title.png"
        alt=""
        style="width: 100%"
      />
    </div>
    <div ref="tabs" id="tabs" class="sticky-container">
      <div class="tag">
        <div
          v-for="(item, index) in tabs"
          class="flex-cc"
          :class="active == item.id ? 'tag-item' : 'tag-item-one'"
          :style="item.style"
          @click="getThere(item)"
          :key="index"
        >
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div class="manage-title-bg1" ref="tagOne" id="tagOne">
      <img class="bg1" src="./img/tuiguang1.png" alt="" style="width: 100%" />
    </div>
    <div class="manage-title-bg1" ref="tagTwo" id="tagTwo">
      <img class="bg1" src="./img/tuiguang2-1.jpg" alt="" style="width: 100%" />
      <div class="video-list">
        <div
          v-for="(item, index) in videoList"
          :key="index"
          @click="openVideoList(item)"
        >
          <img class="bg1" :src="item.src" alt="" style="width: 100%" />
        </div>
      </div>
      <img class="bg1" src="./img/tuiguang2-2.jpg" alt="" style="width: 100%" />
      <div style="width: 100%; background: #e9e9ef">
        <div class="video">
          <div class="video-item">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer0"
              :playsinline="true"
              :options="playerOptions0"
            >
            </video-player>
          </div>
          <div class="video-tips">
            <p>
              中央电视台新闻频道《新闻1+1》做出专题报道，进一步引起全国广泛关注
            </p>
          </div>
        </div>
      </div>
      <img class="bg1" src="./img/tuiguang2-3.jpg" alt="" style="width: 100%" />
      <!-- <img
        class="bg1"
        src="@/image/internet/internet-3-new.jpg"
        alt=""
        style="width: 100%"
      />
      <img
        class="bg1"
        src="@/image/contentplat/tuijian.jpg"
        alt=""
        style="width: 100%"
      />
      <div class="video-box">
        <div class="video">
          <div class="video-item">
            <video-player
              :video-url="videoPath"
              class="video-player vjs-custom-skin"
              ref="videoPlayer0"
              :playsinline="true"
              :options="playerOptions"
            >
            </video-player>
          </div>
        </div>
      </div>
      <div style="display: flex; margin: 0 auto; width: 100%">
        <div
          class="viedo-imgs"
          v-for="(item, index) in videoImage"
          :key="index"
        >
          <img :src="item.src" alt="" @click="switchVideo(item)" />
        </div>
      </div> -->
    </div>
    <div class="manage-title-bg1" ref="tagThree" id="tagThree">
      <img class="bg1" src="./img/tuiguang3-1.jpg" alt="" style="width: 100%" />
      <div class="video-list-grid">
        <div
          class="video-item-grid"
          v-for="(item, index) in videoList3"
          :key="index"
          @click="openVideoList(item)"
        >
          <img class="bg1" :src="item.src" alt="" style="width: 100%" />
        </div>
      </div>
      <img class="bg1" src="./img/tuiguang3-3.jpg" alt="" style="width: 100%" />
    </div>
    <div class="manage-title-bg1" ref="tagFour" id="tagFour">
      <img class="bg1" src="./img/tuiguang-4.jpg" alt="" style="width: 100%" />
      <!-- <div style="padding: 2vh 4vw">
        <swiper :options="swiperOption" ref="mySwiper" :indicator-dots="false">
          slides
          <swiper-slide v-for="(item, index) in swiper_list" :key="index">
            <div>
              <img style="width: 85%" :src="item.src" alt="" />
            </div>
          </swiper-slide>
          Optional controls
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div> -->
      <!-- <img class="bg1" src="./img/tuiguang4.png" alt="" style="width: 100%" />
      <div class="prize-title-bg3">
        <div class="video-item">
          <video-player
            :video-url="videoPath"
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="false"
            :options="playerPrizeOptions"
          >
          </video-player>
        </div>
      </div> -->
    </div>
    <Foot></Foot>
    <el-dialog
      :visible.sync="dialogVisible"
      width="70%"
      top="27vh"
      :show-close="false"
    >
      <video-player
        :video-url="videoPath"
        class="video-player vjs-custom-skin"
        ref="videoPlayer0"
        :playsinline="true"
        :options="playerListOptions"
      >
      </video-player>
    </el-dialog>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";

export default {
  name: "travelMarket",
  components: {
    Head,
    Foot,
  },
  data() {
    return {
      dialogVisible: false,
      playerListOptions: {},
      localKey: "",
      active: 1,
      videoPath: "",
      tabs: [
        {
          name: "营销平台",
          tags: "#tagOne",
          isSelected: true,
          id: 1,
          style: "border-radius: 15px 0 0 15px",
        },
        {
          name: "营销方式",
          tags: "#tagTwo",
          isSelected: false,
          id: 2,
          style: "border-radius: 0",
        },
        {
          name: "营销案例",
          tags: "#tagThree",
          isSelected: false,
          id: 3,
          style: "border-radius: 0",
        },
        {
          name: "营销成效",
          tags: "#tagFour",
          isSelected: false,
          id: 4,
          style: "border-radius: 0 15px 15px 0",
        },
      ],
      videoImage: [
        {
          src: require("@/image/contentplat/videoImage/longnan.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E9%99%87%E5%8D%97.mp4",
        },

        {
          src: require("@/image/contentplat/videoImage/lanzhou.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E5%85%B0%E5%B7%9E.mp4",
        },
        {
          src: require("@/image/contentplat/videoImage/jiuquan.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E9%85%92%E6%B3%89.mp4",
        },
        {
          src: require("@/image/contentplat/videoImage/zhangye.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E5%BC%A0%E6%8E%96.mp4",
        },
        {
          src: require("@/image/contentplat/videoImage/onetime.jpg"),
          url: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E7%94%98%E8%82%83%E4%B8%80%E5%88%86%E9%92%9F.mp4",
        },
      ],
      playerPrizeOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/chenguo/%E5%A4%84%E9%95%BF.mp4", // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../../static/images/test.jpg", //你的封面地址
        poster: require("@/image/achievements/jiangxiang/3.1.jpg"), //你的封面地址

        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/neirongyun/%E9%99%87%E5%8D%97.mp4", // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../../static/images/test.jpg", //你的封面地址
        poster: require("@/image/contentplat/videoImage/longnan.jpg"), //你的封面地址

        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      playerOptions0: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/%E3%80%8A%E6%96%B0%E9%97%BB1%2B1%E3%80%8B20180830%E6%AF%81%E9%81%97%E4%BA%A7%EF%BC%8C%E4%B8%80%E8%84%9A%E5%85%AD%E5%8D%81%E5%B9%B4%EF%BC%9F%E8%AF%A5%E7%BD%9A%E5%A4%9A%E5%B0%91%E9%92%B1%EF%BC%9F%EF%BC%8824%E5%88%86%E9%92%9F%EF%BC%89.mp4", // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../../static/images/test.jpg", //你的封面地址
        poster: require("@/image/internet/video-pre.jpg"), //你的封面地址

        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      swiperOption: {
        indicatorDots: false,
        loop: true,
        spaceBetween: 30,
        slidesPerView: 3,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3000,
        },
        // circular:true
      },
      swiper_list: [
        { src: require("@/image/achievements/jiangxiang/42.jpg") },
        { src: require("@/image/achievements/jiangxiang/43.jpg") },
        { src: require("@/image/achievements/jiangxiang/44.jpg") },
        { src: require("@/image/achievements/jiangxiang/45.jpg") },
        { src: require("@/image/achievements/jiangxiang/46.jpg") },
        { src: require("@/image/achievements/jiangxiang/47.jpg") },

        { src: require("@/image/achievements/jiangxiang/l13.jpg") },
        { src: require("@/image/achievements/jiangxiang/l10.jpg") },
        { src: require("@/image/achievements/jiangxiang/l11.jpg") },
        { src: require("@/image/achievements/jiangxiang/l12.jpg") },
        { src: require("@/image/achievements/jiangxiang/l1.jpg") },
        { src: require("@/image/achievements/jiangxiang/l2.jpg") },
        { src: require("@/image/achievements/jiangxiang/l3.jpg") },
        { src: require("@/image/achievements/jiangxiang/l4.jpg") },
        { src: require("@/image/achievements/jiangxiang/l5.jpg") },
        { src: require("@/image/achievements/jiangxiang/l6.jpg") },
        { src: require("@/image/achievements/jiangxiang/l7.jpg") },
        { src: require("@/image/achievements/jiangxiang/l8.jpg") },
        { src: require("@/image/achievements/jiangxiang/l9.jpg") },
      ],
      videoList: [
        {
          src: require("./img/tuiguang2-1-1.jpg"),
          playerOptions0: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                // src: "//path/to/video.mp4", // 路径
                src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/75e893a26795c07b0c5306c97732713b.mp4", // 路径
                type: "video/mp4", // 类型
              },
            ],
            // poster: "../../static/images/test.jpg", //你的封面地址
            poster: require("./img/tuiguang2-1-1.jpg"), //你的封面地址

            // width: document.documentElement.clientWidth,
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true, //全屏按钮
            },
          },
        },
        {
          src: require("./img/tuiguang2-1-2.jpg"),
          playerOptions0: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                // src: "//path/to/video.mp4", // 路径
                src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/ef11b2332961685644588bb8b4d613cc.mp4", // 路径
                type: "video/mp4", // 类型
              },
            ],
            // poster: "../../static/images/test.jpg", //你的封面地址
            poster: require("./img/tuiguang2-1-2.jpg"), //你的封面地址

            // width: document.documentElement.clientWidth,
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true, //全屏按钮
            },
          },
        },
        {
          src: require("./img/tuiguang2-1-3.jpg"),
          playerOptions0: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                // src: "//path/to/video.mp4", // 路径
                src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/010936a54be84c896669ee00be9adaf0.mp4", // 路径
                type: "video/mp4", // 类型
              },
            ],
            // poster: "../../static/images/test.jpg", //你的封面地址
            poster: require("./img/tuiguang2-1-3.jpg"), //你的封面地址

            // width: document.documentElement.clientWidth,
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true, //全屏按钮
            },
          },
        },
        {
          src: require("./img/tuiguang2-1-4.jpg"),
          playerOptions0: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                // src: "//path/to/video.mp4", // 路径
                src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/044717b43ba4eb0015e06065b882f1e5.mp4", // 路径
                type: "video/mp4", // 类型
              },
            ],
            // poster: "../../static/images/test.jpg", //你的封面地址
            poster: require("./img/tuiguang2-1-4.jpg"), //你的封面地址

            // width: document.documentElement.clientWidth,
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true, //全屏按钮
            },
          },
        },
      ],
      videoList3: [
        {
          src: require("./img/tuiguang3-2-1.png"),
          playerOptions0: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                // src: "//path/to/video.mp4", // 路径
                src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E9%94%80%E6%A1%88%E4%BE%8B%EF%BC%9A%E7%AC%AC%E4%B8%80%E7%BC%95%E9%98%B3%E5%85%89-4%E4%B8%AA%E8%A7%86%E9%A2%91/1-%E5%98%89%E5%B3%AA%E5%85%B3%E9%A3%8E%E5%85%89%E7%AF%87.mp4", // 路径
                type: "video/mp4", // 类型
              },
            ],
            // poster: "../../static/images/test.jpg", //你的封面地址
            poster: require("./img/tuiguang3-2-1.png"), //你的封面地址

            // width: document.documentElement.clientWidth,
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true, //全屏按钮
            },
          },
        },
        {
          src: require("./img/tuiguang3-2-2.png"),
          playerOptions0: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                // src: "//path/to/video.mp4", // 路径
                src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E9%94%80%E6%A1%88%E4%BE%8B%EF%BC%9A%E7%AC%AC%E4%B8%80%E7%BC%95%E9%98%B3%E5%85%89-4%E4%B8%AA%E8%A7%86%E9%A2%91/2-%E5%85%B0%E5%B7%9E%E5%B8%82-%E9%A3%8E%E5%85%89%E8%A7%86%E9%A2%91.mp4", // 路径
                type: "video/mp4", // 类型
              },
            ],
            // poster: "../../static/images/test.jpg", //你的封面地址
            poster: require("./img/tuiguang3-2-2.png"), //你的封面地址

            // width: document.documentElement.clientWidth,
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true, //全屏按钮
            },
          },
        },
        {
          src: require("./img/tuiguang3-2-3.png"),
          playerOptions0: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                // src: "//path/to/video.mp4", // 路径
                src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E9%94%80%E6%A1%88%E4%BE%8B%EF%BC%9A%E7%AC%AC%E4%B8%80%E7%BC%95%E9%98%B3%E5%85%89-4%E4%B8%AA%E8%A7%86%E9%A2%91/3-%E4%B8%B4%E5%A4%8F%E9%A3%8E%E5%85%89%E7%AF%87%E6%88%90%E7%89%8712.14.mp4", // 路径
                type: "video/mp4", // 类型
              },
            ],
            // poster: "../../static/images/test.jpg", //你的封面地址
            poster: require("./img/tuiguang3-2-3.png"), //你的封面地址

            // width: document.documentElement.clientWidth,
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true, //全屏按钮
            },
          },
        },
        {
          src: require("./img/tuiguang3-2-4.png"),
          playerOptions0: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                // src: "//path/to/video.mp4", // 路径
                src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E9%94%80%E6%A1%88%E4%BE%8B%EF%BC%9A%E7%AC%AC%E4%B8%80%E7%BC%95%E9%98%B3%E5%85%89-4%E4%B8%AA%E8%A7%86%E9%A2%91/4-%E5%BC%A0%E6%8E%96%E5%B8%82%E9%A3%8E%E5%85%89%E7%9F%AD%E8%A7%86%E9%A2%91.mp4", // 路径
                type: "video/mp4", // 类型
              },
            ],
            // poster: "../../static/images/test.jpg", //你的封面地址
            poster: require("./img/tuiguang3-2-4.png"), //你的封面地址

            // width: document.documentElement.clientWidth,
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true, //全屏按钮
            },
          },
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.arrDom = document.getElementsByClassName("manage-title-bg1");
    console.log("this.arrDom", this.arrDom);
    this.getLocalStorage();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    openVideoList(item) {
      this.playerListOptions = {};
      this.dialogVisible = true;
      this.playerListOptions = item.playerOptions0;

      console.log("this.currentItem", this.currentItem);
    },
    getLocalStorage() {
      // localStorage.setItem("localKey", JSON.stringify("1"));

      // 页面加载时从LocalStorage恢复数据
      this.localKey = JSON.parse(localStorage.getItem("localKey"));
      console.log("localKey", this.localKey);
    },
    top() {
      // 设置滚动行为改为平滑的滚动
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      for (let i = 0; i < this.arrDom.length; i++) {
        //因为下面使用到了i+1，所以需要把最后一个分离出来判断
        if (this.arrDom[this.arrDom.length - 1].offsetTop - scrollTop > 80) {
          if (
            this.arrDom[i].offsetTop - scrollTop <= 80 &&
            this.arrDom[i + 1].offsetTop - scrollTop > 80
          ) {
            this.active = i + 1;
          }
        } else {
          this.active = this.arrDom.length;
        }
      }
    },
    changeTab(item) {
      this.tabs.forEach((i) => {
        i.isSelected = false;
      });
      item.isSelected = true;
    },
    getThere(item) {
      console.log("查看item", item);
      this.changeTab(item);
      // 通过ref名获取元素在页面中的位置并滚动至此
      this.$el
        .querySelector(item.tags)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    goDetail: function (url) {
      this.$router.push("/iframe?url=" + url);
    },
    goDetailURl(url) {
      // this.$router.push('/iframe?url=' + url)
      // window.location.href =url
      window.open(url);
    },
    switchVideo: function (item) {
      this.playerOptions.poster = item.src;
      this.playerOptions.sources[0].src = item.url;
    },
    goTomediaPage: function (url, type) {
      if (type === "1") {
        this.$router.push("/weiyou");
      } else {
        let params = {
          url: url,
        };
        // localStorage.setItem('urls',url)
        this.$router.push({
          path: "/mediapage",
          query: params,
        });
        // window.console.log(url)
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tag {
  //width: 100%;
  border-radius: 15px;
  //height: 10vh;
  // margin: 0 8.5vw 3vh 8.5vw;
  // padding: 1.5vh;
  height: 8vh;
  width: 83vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //box-shadow:0 0 10px #666;
  box-shadow: 0 10px 60px 5px #333;
}
.tag-item {
  height: 100%;
  // padding: 1.5vh 0;
  flex: 1;
  border-top: 1px solid #42eb6b;
  cursor: pointer;
  background: #ffffff;
  color: #182b3a;
  font-weight: bold;
  font-size: 2vw;
}
.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tag-item-one {
  // padding: 1.5vh 0;

  height: 100%;
  flex: 1;
  cursor: pointer;
  background: #182b3a;
  color: #ffffff;
  font-size: 2vw;
}
.tag-item:hover,
.tag-item-one:hover {
  // animation: pulse;
  // animation-duration: 1s;
}

.head {
  position: sticky;
  top: 0px;
  z-index: 99999999;
}
#tabs {
  margin-top: -40vh;
  // margin-bottom: 8vh;
}
.sticky-container {
  position: sticky;
  top: 10vh;
  z-index: 99999999;
}
.prize-title-bg3 {
  padding: 5vh 10vw;
  margin-top: -25vh;
}
.video {
  margin: 0 auto;
  width: 100%;
  padding: 5vh 10vw;
}
.manage-title-bg1 {
  padding: 12vh 8.5vw;
  // margin: 10vh 0;
}
.video-tips {
  background: #fff;
  padding: 2vh;
  font-size: 0.9vw;
}
.video-box {
  width: 100%;
  margin-top: -25vh;
}
.viedo-imgs img {
  width: 98%;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
/deep/.video-js .vjs-big-play-button {
  top: 40% !important;
  left: 47% !important;
  border-radius: 50% !important;
  height: 3em !important;
  border: none !important;
  line-height: 2.8em !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.viedo-imgs:hover img {
  cursor: pointer;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.top {
  position: fixed;
  right: 45px;
  bottom: 100px;
  width: 50px;
  height: 50px;
  z-index: 99999999;
  box-shadow: 0px 0px 4px 4px #ecefef;
  border-radius: 600px;
  background: #fff;
  cursor: pointer;
}
.video-list {
  display: flex;
  padding: 5vh 8.5vw;
}
.video-list-grid {
  padding: 5vh 8.5vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: vw(14);
  grid-column-gap: vw(14);
}
.video-item-grid img {
  height: 400px; /* 设置统一的高度 */
  object-fit: cover; /* 图片将被裁剪以填充容器，可选cover, contain, fill, none, scale-down */
  width: auto; /* 宽度自动调整以保持图片比例 */
}
/deep/.el-dialog__header {
  display: none !important;
}
/deep/.el-dialog__body {
  padding: 0 !important;
}
</style>
